<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>{{ $t('energy.attic.question') }}</h1>
        </div>
        <div class="ers-page-attic">
            <yes-no v-model="has_attic" />
        </div>
        <div v-if="has_attic" class="ers-page-heated">
            <h1>
                {{ $t('energy.attic.question_heated') }}
                <Tooltip :label="$t('energy.attic.tooltip')" />
            </h1>
            <yes-no v-model="is_attic_heated" />
        </div>
        <div class="is-flex is-justify-content-space-between">
            <b-button
                size="is-medium"
                type="is-dark"
                icon-left="arrow-left"
                disabled
                tag="router-link"
                :to="{
                    name: 'ers.address',
                    params: {
                        lang: $route.params.lang,
                    },
                    query: $route.query,
                }"
            >
                {{ $t('common.previous') }}
            </b-button>
            <submit-button
                @on-click="
                    $emit('open-summary-modal', {
                        unsubmit: false,
                    })
                    nextPage
                "
            />
            <b-button
                v-if="showNextButton"
                :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                size="is-medium"
                type="is-primary"
                icon-right="arrow-right"
                @click="nextPage"
            >
                <span>{{ $t('common.next') }}</span>
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import YesNo from '../../elements/YesNo.vue'
import Tooltip from '../../elements/Tooltip.vue'
import SubmitButton from '../../elements/SubmitButton'

export default {
    name: 'Attic',
    components: { Tooltip, YesNo, SubmitButton },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        has_attic: null,
        is_attic_heated: null,
    }),
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
        showNextButton() {
            if (this.has_attic === false) return true
            if (this.has_attic && this.is_attic_heated !== null) return true
            return false
        },
    },
    watch: {
        getFeatures: {
            handler(newVal, oldVal) {
                this.has_attic = newVal.has_attic
                this.is_attic_heated = newVal.is_attic_heated
            },
            deep: true,
        },

        has_attic(val) {
            if (this.getFeatures.has_attic !== val) {
                if (val === false) {
                    this.is_attic_heated = null
                    this.nextPage()
                }
            }
        },
        is_attic_heated(val) {
            if (this.getFeatures.is_attic_heated !== val) {
                this.nextPage()
            }
        },
    },
    mounted() {
        const prevValue = this.getFeatures

        if (prevValue.has_attic !== null) {
            this.has_attic = prevValue.has_attic
        }
        if (prevValue.is_attic_heated !== null) {
            this.is_attic_heated = prevValue.is_attic_heated
        }
    },
    methods: {
        ...mapMutations('ersStore', ['UPDATE_STATUS']),
        ...mapMutations('ersStore', ['SET_FEATURES']),
        ...mapActions('ersStore', ['set_and_submit_feature']),
        nextPage() {
            const nextPage = 'ers.basement'
            this.set_and_submit_feature({
                feature: {
                    has_attic: this.has_attic,
                    is_attic_heated: this.is_attic_heated,
                },
                nextPage,
            })
            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style scoped lang="scss">
.ers-page {
    &-attic {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        min-width: 100%;
    }

    &-heated {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        min-width: 100%;
    }

    .is-tootip-btn {
        height: 24px;
        width: 24px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
