<template>
    <a v-if="href" :href="href" @click="$emit('btn-click')">
        <slot />
    </a>
    <button v-else @click="$emit('btn-click')">
        <slot />
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        text: {
            type: String,
            default: 'click me!',
        },
        svgIcon: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '#FFFFFF',
        },
        bgColor: {
            type: String,
            default: '#FFFFFF',
        },
        href: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            borderColor: this.$props.color,
            backgroundColor: this.$props.bgColor,
        }
    },
}
</script>

<style scoped>
/* reset */
button,
a {
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
}

button,
a {
    display: flex;
    border: solid 1px black;
    border-radius: 5px;
    padding: 12px 20px 12px 20px;
    font-size: 16px;
    font-weight: 700;
    gap: 10px;
    cursor: pointer;
}
</style>
