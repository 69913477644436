<template>
    <button class="icon-button" @click="$emit('click')">
        <svg-icon :icon="icon" :height="size" :color="color" />
    </button>
</template>
<script>
export default {
    name: 'ClearButton',
    props: {
        icon: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: '16',
        },
        color: {
            type: String,
            default: '#798DA6',
        },
    },
}
</script>
<style lang="scss" scoped>
.icon-button {
    background: rgba(121, 141, 166, 0.15);
    border: none;
    outline: none;
    padding: 8px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: grey;

    &:hover {
        background: rgba(0, 156, 255, 0.15);
    }

    &:hover {
        color: black;
    }
}
</style>
