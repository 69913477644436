<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="welcome-page">
        <div class="welcome-page-content">
            <div class="welcome-page-content__text">
                <h1>
                    {{ `${$t('welcome_page.title')}` }}
                </h1>
                <p>
                    {{ $t('welcome_page.text_welcome') }}
                </p>
                <p>
                    {{ $t('welcome_page.text_intro') }}
                </p>

                <p>
                    {{ $t('welcome_page.text_description') }}
                </p>

                <b-button
                    type="is-primary has-text-weight-medium mb-5"
                    class="start-button"
                    @click="startRequest"
                >
                    {{ $t('welcome_page.button_start') }}
                    <img src="@/assets/img/ers/arrow-right.svg" class="arrow ml-3" />
                </b-button>
            </div>
            <img src="@/assets/img/apps/ers.jpg" />
        </div>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import { mapMutations, mapGetters } from 'vuex'

export default {
    name: 'Welcome',
    computed: {
        ...mapGetters('ersStore', ['getRequestRef']),
        homeGraphic() {
            const lang =
                this.$route.params.lang === 'technical'
                    ? 'en-BE'
                    : this.$route.params.lang
            // necessary for dynamic asset import because Vue adds a hash to asset names
            let imgImport = require.context('@/assets/img/ers', false, /\.png$/)
            return imgImport(
                `./home-graphic-${lang}${this.$vssWidth < 768 ? '-mobile' : ''}.png`
            )
        },
        termsLink() {
            const lang =
                this.$route.params.lang === 'technical'
                    ? 'en-BE'
                    : this.$route.params.lang
            if (lang === 'en-BE') return 'https://www.rock.estate/privacy'
            return `https://www.rock.estate/privacy-policy-${lang.split('-')[0]}`
        },
        previewLink() {
            let lang =
                this.$route.params.lang === 'technical'
                    ? 'EN'
                    : this.$route.params.lang.split('-')[0].toUpperCase()
            return this.$config[`EXAMPLE_REPORT_URL_${lang}`]
        },
    },
    mounted() {
        this.RESET_STORE()
    },
    methods: {
        ...mapMutations('ersStore', ['SET_REQUEST_REF', 'RESET_STORE']),
        startRequest() {
            this.$router.push({
                name: 'ers.customer-ref',
                params: {
                    lang: this.$route.params.lang,
                },
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.welcome-page {
    display: flex;
    padding: 6.25rem;
    flex-direction: column;
    justify-content: center;
    background: #f5f6fa;

    .welcome-page-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex: 1 0 0;
        min-width: 100%;
        align-items: center;
        margin-inline: auto;
        &__text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
            max-width: 37.5rem;
            p {
                color: #798da6;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 1.6875rem */
            }
            h1 {
                color: #001837;
                font-size: 2.625rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
        img {
        }
    }

    .start-button {
        height: fit-content !important;
        padding: 12px 20px !important;
    }
}
</style>
